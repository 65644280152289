<template>
  <div class="main">
        <h1>
            平台用户服务协议
        </h1>

        <h2>1、协议确认</h2>
        <div class="wenzi">
            1.1 企盈多及其涉及到的产品、相关软件的所有权和运作权归企盈多，企盈多享有对本平台上一切活动的监督、提示、检查、纠正及处罚等权利。用户注册成功后，无论是进入企盈多企业财税服务平台，还是在企盈多上从事包括但不限于以下活动：分享、学习、评论等，即表示用户与企盈多已达成协议，自愿接受本服务协议的所有内容。如果用户不同意服务协议的条款，则可以选择不进入企盈多企业财税服务平台。用户直接或通过各类方式（如 RSS 源和站外 API 引用等）间接使用企盈多服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用企盈多所提供的全部服务。
        </div>
        <div class="wenzi">
            1.2 根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，企盈多有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，企盈多将会直接在网站上公布修改之后的协议内容，该公布行为视为企盈多已经通知用户修改内容。企盈多也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。如果不同意企盈多对本协议相关条款所做的修改，用户有权并应当停止使用企盈多。如果用户继续使用企盈多，则视为用户接受企盈多对本协议相关条款所做的修改。
        </div>

        <h2>2、使用规则</h2>
        <div class="wenzi">
            2.1 用户注册成功后，企盈多将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
        </div>
        <div class="wenzi">
            2.2 用户承诺不得以任何方式利用企盈多直接或间接从事违反中国法律、以及社会公德的行为，企盈多有权对违反上述承诺的内容予以删除。
        </div>
        <div class="wenzi">
            2.3 用户不得利用企盈多服务制作、上载、复制、发布、传播或者转载如下内容：
        </div>
        <ul>
            <li>(1) 反对宪法所确定的基本原则的；</li>
            <li>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
            <li>(3) 损害国家荣誉和利益的；</li>
            <li>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</li>
            <li>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
            <li>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
            <li>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
            <li>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</li>
            <li>(9) 含有法律、行政法规禁止的其他内容的信息。</li>
        </ul>
        <div class="wenzi">
            2.4 用户不得做出侵犯其他用户或他人权益的行为，包括但不限于：
        </div>
        <ul>
            <li>(1) 恶意攻击、辱骂、诽谤、骚扰他人；</li>
            <li>(2) 威胁他人的人身安全、法律安全；</li>
            <li>(3) 恶意泄露他人个人隐私；</li>
            <li>(4) 冒充他人；</li>
            <li>(5) 其他侵犯第三方合法权益的行为。</li>
        </ul>
        <div class="wenzi">
            2.5 用户不得做出破坏企盈多运营安全或服务质量的行为和内容：
        </div>
        <ul>
            <li>(1) 群发广告；</li>
            <li>(2) 使用程序操作；</li>
            <li>(3) 发布垃圾内容，包括但不限于：评论、私信、教室名称、详细介绍；</li>
            <li>(4) 恶意发布重复内容；</li>
            <li>(5) 使用马甲等作弊手段操作内容和排序。</li>
        </ul>
        <div class="wenzi">
            2.6 企盈多有权对用户使用企盈多的情况进行审查和监督。如用户在使用企盈多时违反任何上述规定，企盈多或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用企盈多的权利）以减轻用户不当行为造成的影响。因用户进行上述内容在企盈多的上载或发布，而导致任何第三方提出索赔要求或衍生的任何损害或损失，由用户承担全部责任。
        </div>

        <h2>3、用户发布的内容的知识产权</h2>
        <div class="wenzi">
            企盈多鼓励用户在企盈多上创造和发布的内容可用于传播和共享。
        </div>
        <div class="wenzi">
            3.1 用户发布的内容指用户在企盈多上上载、创造、发布的任何内容，包括但不限于文字、图片、音频、视频等。企盈多鼓励用户发布的内容用于传播和共享。
        </div>
        <div class="wenzi">
            3.2 除非收到相应通知，企盈多视该用户为发布的内容的版权所有人。用户在企盈多上载、创造、发布内容即视为其同意就上述所有内容授予企盈多（包括其继受者、关联方，下同）在全球范围内免费、不可撤销的、无期限的并且可转让的非独家使用权许可。企盈多或其授权的第三方有权将前述内容用于其他合法用途，包括但不限于部分或全部地复制、修改、改编、翻译、推广、分发、广播、表演、演绎、出版。但第三方不得再次授权他人使用前述内容。
        </div>
        <div class="wenzi">
            3.3 在用户删除、移除已发布的内容后，用户上述授权在经过一段合理的时间内后终止。但用户理解并同意，企盈多将保留其删除、移除的内容，但在一段合理时间后将不再展示、传播、复制用户已经删除、移除的内容。已经传播、散布、推广至其他平台的内容，则会继续播放。
        </div>
        <div class="wenzi">
            3.4 企盈多不为用户发布的内容相关的准确性、实用性、安全性、知识产权承担任何责任。
        </div>
        <div class="wenzi">
            3.5 如果任何第三方侵犯了企盈多用户相关的权利，用户同意授权企盈多或其指定的代理人代表企盈多自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在企盈多认为必要的情况下参与共同维权。
        </div>
        <div class="wenzi">
            3.6 如个人或单位发现企盈多上存在侵犯其自身合法权益的内容，请及时与企盈多取得联系
            <span class="redwen">（邮箱：zhongshuiyunfuwu@163.com）</span>
             ，并提供具有法律效力的证明材料，以便企盈多作出处理。包含的书面证明材料如下：
        </div>
        <ul>
            <li>(1) 涉嫌侵权内容所有者的权属证明；</li>
            <li>(2) 明确的身份证明、住址、联系方式；</li>
            <li>(3) 涉嫌侵权内容在企盈多的位置、描述和著作权遭受侵犯的证明；</li>
            <li>(4) 其他必要的具有法律效力的证明材料。</li>
        </ul>
        <div class="wenzi">
            一旦收到符合上述要求之通知，我们将在合理时间内删除涉嫌侵权的作品。如不符合上述条件，我们会请阁下提供相应信息，且暂不采取包括删除等相应措施。 在企盈多录制或上传作品的用户视为同意企盈多就前款情况所采用的相应措施。企盈多不因此而承担任何违约责任或其他任何法律责任。企盈多在收到上述通知后会发送电子邮件通知上载该等作品的用户。对于多次上载涉嫌侵权作品的用户，我们将取消其用户资格。
        </div>
        <div class="wenzi">
            3.7 为便于区分侵权行为，用户通过屏幕录制、拍摄屏幕、录音等形式，将其在企盈多录制的课程授权其他人使用，应通知企盈多
            <span class="redwen">（邮箱：zhongshuiyunfuwu@163.com）</span> 
             ，以避免误会和纠纷。通过企盈多站提供的分享功能，则不受此限。
        </div>

        <h2>4、企盈多的知识产权及其他权利</h2>
        <div class="wenzi">
            4.1 企盈多对网站服务及本网站所使用的软件所包含的受知识产权或其他法律保护的资料享有相应的权利；除用户依法享有之版权之内容之外，本网站的整体内容版权归企盈多。
        </div>
        <div class="wenzi">
            4.2 用户对企盈多所使用的软件有非专属性使用权，但不得自行或许可任何第三方复制、修改、出售或衍生产品。
        </div>
        <div class="wenzi">
            4.3 企盈多所有设计图样以及其他图样、图标、产品及服务名称，均为企盈多或其关联公司所享有。任何人不得使用、复制或用作其他用途。
        </div>
        <div class="wenzi">
            4.4 企盈多对其专有内容、原创内容和其他通过授权取得的独占或则独家内容享有完全知识产权。在未经企盈多同意的情况下，第三方不能对企盈多平台中的内容做出任何形式的抓取和修改（包括但不限于通过屏幕录制、拍摄屏幕、录音等任何形式将用户发布的内容转制为视频、音频、图片、文字等任何形式的文件，以及对作品进行片段截取，文字转制等任何类似行为）演绎、衍生、出版、发行及以其他方式使用。否则，将承担侵犯知识产权的法律责任。
        </div>

        <h2>5、免责条款</h2>
        <div class="wenzi">
            5.1 对用户从企盈多获得的任何信息、内容或者广告宣传等任何资讯（以下统称"信息"），企盈多均无法担保真实、准确和完整性，企盈多不对任何直接发生的交易和/或行为承担任何直接、间接、附带或衍生的损失和责任。
        </div>
        <div class="wenzi">
            5.2 用户使用经由企盈多下载的或取得的任何资料，其风险自行负担；因该等使用导致用户电脑系统损坏或资料流失，企盈多不承担责任。
        </div>
        <div class="wenzi">
            5.3 如用户在企盈多不正当或非法使用产品服务、购买商品或者发生跟企盈多服务有关第三方网络交易、而导致的所有直接或间接的损害，企盈多均不承担责任。
        </div>
        <div class="wenzi">
            5.4 企盈多对各项服务下涉及的境内外基础电信运营商的移动通信网络的故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击、用户所在位置、用户关机或其他非企盈多技术能力范围内的事因等造成的服务中断、用户发送的短信息的内容丢失、出现乱码、错误接收、无法接收、迟延接收事项不作担保，但将尽力减少因此而给用户造成的损失和影响。
        </div>

        <h2>6、其它</h2>
        <div class="wenzi">
            6.1 通知方式 企盈多将通过网站公告、发送电子邮件、常规信件传送等方式来发送通知、公告给用户。告知用户服务协议的修改、服务变更或其他重要事情。同时，用户理解并同意企盈多拥有投放商业性广告的权利。
        </div>
        <div class="wenzi">
            6.2 法律适用 本服务协议之效力和解释均适用中华人民共和国之法律。用户和企盈多一致同意有关本条款引起的相关争议，
            <span class="redwen">以北京市海淀区人民法院为一审管辖法院</span>
             。如服务协议之任何一部分与中华人民共和国法律相抵触，则该部分条款应按法律规定重新解释，部分条款之无效或重新解释不影响其它条款之法律效力。
        </div>
        <div class="wenzi">
            6.3 个人隐私尊重用户个人隐私信息是企盈多的一贯原则，企盈多将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，企盈多保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善企盈多的技术与服务，企盈多将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于企盈多向用户提供更好的用户体验和服务质量。
        </div>
        <div class="wenzi">
            6.4 用户对服务之任何部分或本服务协议的任何部分之意见及建议可通过用户服务部门与企盈多联系。
        </div>

        
  </div>
</template>

<script>
export default {
    name: "ptyhfwxy",
}
</script>

<style scoped>
    .main {
        /* background:#eee; */
        padding: 20px 30px 50px 30px;
        min-height: 100vh;
    }
    h1{
        text-align: center;
        font-weight: 500;
        margin-bottom: 40px;
    }
    h2{
        font-weight: 500;
    }
    .wenzi{
        text-indent: 2em;
        margin: 20px 0;
    }
    ul{
        padding-left: 36px;
        margin-top: -10px;
    }
    li{
        padding: 2px 0;
    }
    .redwen{
        color: #c20000;
    }
</style>